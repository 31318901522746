import React from 'react';
import './App.css';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <h1>Pitch Pal</h1>
          <p>A tool to perfect your cold outreach!</p>
        </header>
        <section className="App-content">
          <h2>Welcome to Pitch Pal!</h2>
          <p>
            Pitch Pal helps you draft cold outreach, whatever the product or industry!
              We speed up the process so you are free to focus on what matters.
          </p>
        </section>
        <footer className="App-footer">
          <p>© 2023 Pitch Pal. All rights reserved.</p>
        </footer>
      </div>
  );
}

export default App;
